import { configureStore } from '@reduxjs/toolkit';
import { selfeAppSlice } from './features/app/selfeAppSlice';
import { printingAppSlice } from './features/app/printingAppSlice';
import { elementPhotoSlice } from './features/app/elementSlice';
import { filterPhotoSlice } from './features/app/filterSlice';
import { framePhotoSlice } from './features/app/frameSlice';
import { layoutPhotoSlice } from './features/app/layoutSlice';
import { themePhotoSlice } from './features/app/themeSlice';
import { photoTakenSlice } from './features/app/photoTakenSlice';
import { boothSlice } from './features/app/boothSlice';
import { trackingSlice } from './features/app/trackingSlice';
import { counterSlice } from './features/counter/counterSlice';
import { baseSliceApi, robotArmBaseSliceApi } from './api/base.slice-api';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [baseSliceApi.reducerPath]: baseSliceApi.reducer,
    [robotArmBaseSliceApi.reducerPath]: robotArmBaseSliceApi.reducer,
    booth: boothSlice.reducer,
    selfeApp: selfeAppSlice.reducer,
    printingApp: printingAppSlice.reducer,
    tracking: trackingSlice.reducer,
    elementPhoto: elementPhotoSlice.reducer,
    filterPhoto: filterPhotoSlice.reducer,
    framePhoto: framePhotoSlice.reducer,
    layoutPhoto: layoutPhotoSlice.reducer,
    themePhoto: themePhotoSlice.reducer,
    photoTaken: photoTakenSlice.reducer,
    counter: counterSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseSliceApi.middleware,
      robotArmBaseSliceApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
