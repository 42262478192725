import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { find, map, range, slice } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setSelfeAppStateAction } from 'store/features/app/selfeAppSlice';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { UseCarouselTypes } from 'hooks/useCarousel';
import { useBoothAppContext } from 'context/BoothAppContext';
import { useSoundContext } from 'context/SoundContext';
import {
  INIT_BACKGROUND_CATEGORY_ID,
  INIT_BACKGROUND_ID,
  ITEM_CATEGORY_SELECTABLE_PER_PAGE,
  ITEM_SELECTABLE_PER_PAGE,
  INIT_STICKER_CATEGORY_ID,
  PHOTO_WITH_BG_PREFIX,
  SHOOTING_TYPES,
  VERTICAL_SCREEN_ITEM_SELECTABLE_PER_PAGE,
} from 'constants/photo.const';
import { I18nNamespace } from 'constants/i18n.const';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { Carousel } from 'components/carousel/Carousel';
import PhotoSheet from 'components/photo-sheet/PhotoSheet';
import Button from 'components/button/Button';
import { processedImageUrl, resourceUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';
import './select-background.css';
import { useModal } from '../../../hooks/useModal';

function SelectBackground() {
  const { t } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const { audio } = useSoundContext();
  const {
    listBackgroundCategoryPhotos,
    themeDetail,
    currentLayout,
    listFilterPhotos,
    listBackgroundPhotos,
  } = useBoothAppContext();
  const {
    transactionId,
    pickedPhotos,
    backgroundId,
    backgroundCategoryId,
    shootingModeId,
    filterId,
  } = useAppSelector((state) => state.selfeApp);
  const { isVerticalScreen } = useAppSelector((s) => s.booth);
  const [isInBackgroundScreen, setIsInBackgroundScreen] = useState(false);
  const isHasVideo =
    !shootingModeId || isEqualVal(shootingModeId, SHOOTING_TYPES.COUNTDOWN);
  // const { second } = usePageTimer();
  const [stickerCarosel, setStickerCarousel] =
    useState<UseCarouselTypes | null>(null);

  const { openConfirmModal, closeConfirmModal } = useModal();

  const currentFilter = find(listFilterPhotos?.data, (o) =>
    isEqualVal(o?.id, filterId),
  );
  const handleSelectBackground = (id: number) => () => {
    audio?.playClickToSelect?.();
    setIsInBackgroundScreen(true);
    if (id === backgroundId) {
      dispatch(setSelfeAppStateAction({ backgroundId: INIT_BACKGROUND_ID }));
    } else {
      dispatch(setSelfeAppStateAction({ backgroundId: id }));
    }
  };

  const handleSelectCategory = (category: number | undefined) => () => {
    if (category) {
      audio?.playClickToSelect?.();
      stickerCarosel?.setActive?.(0);
      dispatch(setSelfeAppStateAction({ backgroundCategoryId: category }));
    }
  };

  useEffect(() => {
    !backgroundCategoryId &&
      dispatch(
        setSelfeAppStateAction({
          backgroundCategoryId:
            listBackgroundCategoryPhotos?.data?.[0]?.id ||
            INIT_BACKGROUND_CATEGORY_ID,
        }),
      );
  }, [dispatch, listBackgroundCategoryPhotos, backgroundCategoryId]);

  useEffect(() => {
    if (!backgroundId && !isInBackgroundScreen) {
      openConfirmModal({
        RenderContent: () => (
          <div>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              color={TypographyColor.SECONDARY}
            >{`${t('common:warningBackgroundTitle')}`}</Typography>
            <Typography
              variant={TYPOGRAPHY_VARIANTS.BODY}
              color={TypographyColor.SECONDARY}
            >{`${t('common:warningBackground')}`}</Typography>
          </div>
        ),
        NextButtonProps: { children: t('common:confirm') },
        hidePrev: true,
        isUseTimer: true,
        isTimerInBtnNext: true,
        onOk: () => {
          closeConfirmModal?.();
        },
        onTimeUp: () => {
          closeConfirmModal();
        },
        onClose: () => {
          closeConfirmModal();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundId, isInBackgroundScreen]);

  useEffect(() => {
    dispatch(
      setSelfeAppStateAction({
        backgroundId: backgroundId || INIT_BACKGROUND_ID,
      }),
    );
  }, [dispatch, backgroundId]);

  const backgroundPerPage = useMemo(
    () =>
      isVerticalScreen
        ? VERTICAL_SCREEN_ITEM_SELECTABLE_PER_PAGE
        : ITEM_SELECTABLE_PER_PAGE,
    [isVerticalScreen],
  );

  return (
    <>
      <div className="background-page">
        <div className="text-center background-page__background-category-carousel--wrapper">
          <Carousel
            className="background-page__background-category-carousel"
            hiddenDot
            hiddenBtn={false}
            slides={map(
              range(
                1,
                Math.ceil(
                  (listBackgroundCategoryPhotos?.totalRecord || 0) /
                    ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                ) + 1,
              ),
              (_, ind) => (
                <div className="button-group">
                  {map(
                    slice(
                      listBackgroundCategoryPhotos?.data,
                      ind * ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                      (ind + 1) * ITEM_CATEGORY_SELECTABLE_PER_PAGE,
                    ),
                    (category) => {
                      const isCateActive = isEqualVal(
                        backgroundCategoryId || INIT_BACKGROUND_CATEGORY_ID,
                        category?.id,
                      );
                      return (
                        <Button
                          key={category?.id}
                          color={isCateActive ? 'primary' : 'transparent'}
                          onClick={handleSelectCategory(category?.id)}
                          className={cx('category-button', {
                            'category-button-active': isCateActive,
                          })}
                          TypographyProps={{
                            className: 'text-ellipsis overflow-hidden',
                          }}
                        >
                          {category?.name}
                        </Button>
                      );
                    },
                  )}
                </div>
              ),
            )}
          />
        </div>
        <div className="background-page__selection-section">
          <div className="background-page__background-photo-sheet--wrapper">
            <PhotoSheet
              className="background-page__background-photo-sheet"
              data={{
                ...currentLayout,
                pictures: map(currentLayout?.pictures, (item, ind: number) => ({
                  ...item,
                  image: pickedPhotos?.[ind]
                    ? processedImageUrl(
                        transactionId,
                        backgroundId
                          ? `${PHOTO_WITH_BG_PREFIX}${pickedPhotos?.[ind]?.fileName}`
                          : pickedPhotos?.[ind]?.fileName,
                      )
                    : '',
                  rotate: pickedPhotos?.[ind]?.rotate,
                  flip: pickedPhotos?.[ind]?.flip,
                })),
                image: themeDetail?.image || currentLayout?.image,
              }}
              filterValues={currentFilter?.colorMatrix}
            />
            {isHasVideo && (
              <Typography
                className="font-semibold mt-7 background-page__warning-text"
                variant={TYPOGRAPHY_VARIANTS.SMALL}
                firstCapCase
              >
                {t('page:warningSticker')}
              </Typography>
            )}
          </div>
          <div className="background-page__background-carousel--wrapper">
            <Carousel
              setCarousel={setStickerCarousel}
              className="background-page__background-carousel"
              slides={map(
                range(
                  1,
                  Math.ceil(
                    (listBackgroundPhotos?.totalRecord || 0) /
                      backgroundPerPage,
                  ) + 1,
                ),
                (_, ind) => (
                  <div className="theme-group">
                    {map(
                      slice(
                        listBackgroundPhotos?.data,
                        ind * backgroundPerPage,
                        (ind + 1) * backgroundPerPage,
                      ),
                      (item) => (
                        <button
                          key={item?.id}
                          type="button"
                          className={cx('background-item', {
                            active: isEqualVal(item?.id, backgroundId),
                          })}
                          onClick={handleSelectBackground(item?.id)}
                        >
                          <img
                            src={resourceUrl(item?.imageUrl)}
                            alt="theme-img"
                          />
                        </button>
                      ),
                    )}
                  </div>
                ),
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectBackground;
