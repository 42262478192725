import React from 'react';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import './printing-select-photo.css';
import { filter, findIndex, map, size } from 'lodash';
import { AssetIcon } from 'assets/icon/Icons';
import { setPrintingAppStateAction } from 'store/features/app/printingAppSlice';
import { includesId } from 'helpers/array.helper';
import { resourceUrl } from 'helpers/url.helper';
import { isEqualVal } from 'helpers/string.helper';

function PrintingSelectPhoto() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const { isEnoughDeposit } = useCheckPayment();

  const { numberOfPrint, uploadedPhoto, selectedUploadedPhoto } =
    useAppSelector((state) => state.printingApp);

  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // click Back
  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const handleSelectPhoto = (fileName: string) => () =>
    dispatch(
      setPrintingAppStateAction({
        // eslint-disable-next-line no-nested-ternary
        selectedUploadedPhoto: includesId(selectedUploadedPhoto, fileName)
          ? filter(selectedUploadedPhoto, (o) => !isEqualVal(o, fileName))
          : size(selectedUploadedPhoto) >= numberOfPrint
          ? selectedUploadedPhoto
          : [...selectedUploadedPhoto, fileName],
      }),
    );

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleNext}
      className={cx({
        'printing-select-photo__page-actions--vertical': isVerticalScreen,
      })}
      // hideNext
    />
  );

  return (
    <>
      <div className="printing-select-photo">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin printing-select-photo__page-title">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:uploadedPhoto`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:uploadedPhoto`)}
            </Typography>
          </div>
        )}
        <div className="printing-select-photo__wrapper">
          <div className={cx('printing-select-photo__payment-info-wrapper')}>
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
              Số lượng in: 04
            </Typography>
            <Typography variant={TYPOGRAPHY_VARIANTS.BODY}>
              Thành tiền: 50.000đ
            </Typography>
          </div>
          <div className="printing-select-photo__photo-grid">
            {map(uploadedPhoto, (photo, index) => {
              const selectedPhotoIndex = findIndex(selectedUploadedPhoto, (o) =>
                isEqualVal(o, photo),
              );
              const isSelectedPhoto = selectedPhotoIndex >= 0;

              return (
                <button
                  type="button"
                  className={cx('printing-select-photo__photo-item', {
                    active: selectedPhotoIndex >= 0,
                  })}
                  onClick={handleSelectPhoto(photo)}
                >
                  <img
                    src={resourceUrl(photo)}
                    alt="uploaded-img"
                    className="no-drag"
                  />
                  <div className="printing-select-photo__actions">
                    <button
                      type="button"
                      className="printing-select-photo__action-picture"
                    >
                      <AssetIcon.PictureOutline />
                    </button>
                    <button
                      type="button"
                      className="printing-select-photo__action-trash"
                    >
                      <AssetIcon.TrashOutline />
                    </button>
                    {isSelectedPhoto && (
                      <Typography
                        className="font-black printing-select-photo__order-text"
                        variant={TYPOGRAPHY_VARIANTS.XS}
                      >
                        {selectedPhotoIndex + 1}
                      </Typography>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:uploadedPhoto`)} />
      )}
    </>
  );
}

export default PrintingSelectPhoto;
