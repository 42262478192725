import { AnyAction, AsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PrintingAppStateModel } from 'models/app.model';
import {
  INIT_TRANSACTION_ID,
  PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT,
} from 'constants/photo.const';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled');
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending');
}

export const INITIAL_PRINTING_STATE: PrintingAppStateModel = {
  transactionId: INIT_TRANSACTION_ID,

  numberOfPrint: 4,
  isPrintingPortrait: false,
  isPrintingPhoto: false,

  uploadedPhoto: [
    '1.png',
    '2.png',
    '3.png',
    '4.png',
    '5.png',
    '6.png',
    '7.png',
    '8.png',
    '9.png',
    '10.png',
    '11.png',
    '12.png',
  ],
  selectedUploadedPhoto: [],

  LifeCycleStep: PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT,

  appLoading: false,
};

export const printingAppSlice = createSlice({
  name: 'printing-app',
  initialState: INITIAL_PRINTING_STATE,
  reducers: {
    setPrintingAppStateAction: (
      state,
      action: PayloadAction<Partial<PrintingAppStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PrintingAppStateModel),
    resetPrintingAppStateAction: () => ({ ...INITIAL_PRINTING_STATE }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state) => ({
        ...state,
        appLoading: true,
      }))
      .addMatcher(isFulfilledAction, (state) => ({
        ...state,
        appLoading: false,
      }))
      .addMatcher(isRejectedAction, (state) => ({
        ...state,
        appLoading: false,
      }));
  },
});

export const { setPrintingAppStateAction, resetPrintingAppStateAction } =
  printingAppSlice.actions;

export default printingAppSlice.reducer;
