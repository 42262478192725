/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cx from 'classnames';
import Typography, { TypographyColor } from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'hooks/useTranslation';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { useAppSelector } from 'store/store-hooks';
import { usePageTimer } from 'hooks/usePageTimer';
import TimerText from 'components/timer-text/TimerText';
import { AssetImage } from 'assets/v2/images/AssetImage';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import { useNavigate } from 'react-router-dom';
import './printing-start-page.css';

function PrintingStartPage() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { isVerticalScreen } = useAppSelector((s) => s.booth);
  const { getNextPath } = useNewPhotoLifeCycleStep();

  const { second } = usePageTimer({
    forwardFunc: () => handleSelectPaymentMethod()(),
  });

  // handle select frame
  const handleSelectPaymentMethod = () => () => {
    console.log('ttt next', getNextPath());
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  return (
    <>
      <div className="print-start-page">
        <TimerText second={second} />
        {!isVerticalScreen && (
          <div className="print-start-page__title page-title-margin">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:whatDoYouWantToPrint`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:whatDoYouWantToPrint`)}
            </Typography>
          </div>
        )}
        <div className="print-start-page__btn-group">
          <div
            className={cx('dashed-border-select-frame print-start-page__item')}
          >
            <button
              type="button"
              className="print-start-page__btn"
              onClick={handleSelectPaymentMethod()}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="print-start-page__btn--title uppercase text-center font-semibold"
                color={TypographyColor.SECONDARY}
              >
                {T(`${I18nNamespace.COMMON}:printingPhoto`)}
              </Typography>
              <img
                className="print-start-page__mascot"
                src={AssetImage.PrintingPhoto}
                alt=""
              />
            </button>
          </div>
          <div
            className={cx(
              'dashed-border-select-frame print-start-page__item print-start-page__item--right',
            )}
          >
            <button
              type="button"
              className="print-start-page__btn"
              onClick={handleSelectPaymentMethod()}
            >
              <Typography
                variant={TYPOGRAPHY_VARIANTS.H2}
                className="print-start-page__btn--title uppercase text-center font-semibold"
                color={TypographyColor.SECONDARY}
              >
                {T(`${I18nNamespace.COMMON}:printPortrait`)}
              </Typography>
              <img
                className="print-start-page__mascot"
                src={AssetImage.PrintingPortrait}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
      {isVerticalScreen && (
        <TitleAndLogo
          text={T(`${I18nNamespace.COMMON}:whatDoYouWantToPrint`)}
        />
      )}
    </>
  );
}

export default PrintingStartPage;
