/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react';
import useMouseEvents from 'beautiful-react-hooks/useMouseEvents';
import useTouchEvents from 'beautiful-react-hooks/useTouchEvents';
import { size, toNumber } from 'lodash';
import cx from 'classnames';
import './cropper.css';

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  imgSrc: string;
};

const Cropper: FC<Props> = ({ imgSrc, className, ...rest }) => {
  const containerBoxRef = useRef<any>(null);
  const cropRef = useRef<any>(null);
  const cropImageRef = useRef<any>(null);

  const { onTouchStart: onTouchStartInCrop, onTouchMove: onTouchMoveInCrop } =
    useTouchEvents(cropRef);

  const {
    onMouseMove: onMouseMoveInCrop,
    onMouseDown: onMouseDownInCrop,
    onMouseUp: onMouseUpInCrop,
    onMouseLeave: onMouseLeaveInCrop,
  } = useMouseEvents(cropRef);

  const [cropPos, setCropPos] = useState({ x: 0, y: 0 });

  const stopToMove = () =>
    cropRef.current && cropRef.current.removeAttribute('data-mouse-start');

  const dragToMove = (event: any) => {
    if (cropRef.current) {
      const startMouseX =
        cropRef.current.getAttribute('data-mouse-start') || null;

      if (!startMouseX) return false;

      if (event.target && containerBoxRef.current) {
        const rect = cropRef.current.getBoundingClientRect();
        const containerRect = containerBoxRef.current.getBoundingClientRect();
        const clientX =
          event.clientX || (event as TouchEvent).touches?.[0].clientX;

        const offsetX = clientX - containerRect.x - +startMouseX;
        const limitOffsetX = Math.min(
          Math.max(offsetX, 0),
          containerRect.width - rect.width,
        );
        setCropPos({ x: limitOffsetX, y: 0 });
      }
    }
  };

  onTouchStartInCrop((event: TouchEvent) => {
    if (cropRef.current && size(event.touches) === 1) {
      const rect = cropRef.current.getBoundingClientRect();
      cropRef.current &&
        cropRef.current.setAttribute(
          'data-mouse-start',
          `${toNumber(event.touches?.[0].clientX) - toNumber(rect.x)}`,
        );
    }
  });

  onTouchMoveInCrop((event) => {
    dragToMove(event as any);
  });

  onMouseDownInCrop((event) => {
    if (cropRef.current) {
      const rect = cropRef.current.getBoundingClientRect();
      cropRef.current &&
        cropRef.current.setAttribute(
          'data-mouse-start',
          `${toNumber(event.clientX) - toNumber(rect.x)}`,
        );
    }
  });

  onMouseMoveInCrop((event) => {
    dragToMove(event as any);
  });

  onMouseUpInCrop(() => {
    stopToMove();
  });

  onMouseLeaveInCrop(() => {
    stopToMove();
  });

  useEffect(() => {
    if (containerBoxRef.current && cropImageRef.current) {
      const containerRect = containerBoxRef.current.getBoundingClientRect();
      cropImageRef.current.style.width = `${containerRect.width}px`;
      cropImageRef.current.style.height = `${containerRect.height}px`;
    }
  }, []);

  return (
    <div
      {...rest}
      ref={containerBoxRef}
      className={cx('pb-cropper pb-cropper__container-box', className)}
    >
      <img
        className="pb-cropper-container-image"
        src={imgSrc}
        alt="crop box img"
      />
      <div className="pb-cropper__backdrop" />
      <div
        ref={cropRef}
        className="pb-cropper__crop-box"
        style={{ left: cropPos?.x }}
      >
        <span className="pb-cropper__view-box">
          <img
            className="pb-cropper__view-box-image"
            ref={cropImageRef}
            src={imgSrc}
            alt="crop box img"
            style={{
              transform: `translateX(${-cropPos?.x}px) translateY(${-cropPos?.y}px)`,
            }}
          />
        </span>
        <span className="pb-cropper__dashed dashed-h" />
        <span className="pb-cropper__dashed dashed-v" />
        <span className="pb-cropper__center" />
        <span
          className="pb-cropper__face cropper-move"
          data-cropper-action="all"
        />
        <span className="pb-cropper__line line-e" data-cropper-action="e" />
        <span className="pb-cropper__line line-n" data-cropper-action="n" />
        <span className="pb-cropper__line line-w" data-cropper-action="w" />
        <span className="pb-cropper__line line-s" data-cropper-action="s" />
        <span className="pb-cropper__point point-e" data-cropper-action="e" />
        <span className="pb-cropper__point point-n" data-cropper-action="n" />
        <span className="pb-cropper__point point-w" data-cropper-action="w" />
        <span className="pb-cropper__point point-s" data-cropper-action="s" />
        <span
          className="pb-cropper__point pb-cropper__point-corner point-ne"
          data-cropper-action="ne"
        />
        <span
          className="pb-cropper__point pb-cropper__point-corner point-nw"
          data-cropper-action="nw"
        />
        <span
          className="pb-cropper__point pb-cropper__point-corner point-sw"
          data-cropper-action="sw"
        />
        <span
          className="pb-cropper__point pb-cropper__point-corner point-se"
          data-cropper-action="se"
        />
      </div>
    </div>
  );
};

export default Cropper;
