import React from 'react';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import { I18nNamespace } from 'constants/i18n.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/store-hooks';
import './printing-photo-upload.css';

function PrintingPhotoUpload() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const { isEnoughDeposit } = useCheckPayment();
  const { momoTransactionData } = useAppSelector((state) => state.selfeApp);
  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // click Back
  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleNext}
      className={cx({
        'printing-photo-upload__page-actions--vertical': isVerticalScreen,
      })}
      // hideNext
    />
  );

  return (
    <>
      <div className="printing-photo-upload">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin mb-[6rem]">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)}
            </Typography>
          </div>
        )}
        <div className="flex items-center justify-center printing-photo-upload__wrapper">
          <div className="dashed-border">
            <div className="printing-photo-upload__qr-wrapper">
              <QRCodeCanvas
                style={{ height: 'auto', maxWidth: '100%', width: '48rem' }}
                value={momoTransactionData?.qrCodeUrl || ''}
              />
              <div className={cx('printing-photo-upload__qr-link-wrapper')}>
                <Typography variant={TYPOGRAPHY_VARIANTS.SMALL}>
                  funstudio.vn/print/12345678
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:scanQRToUploadPhoto`)} />
      )}
    </>
  );
}

export default PrintingPhotoUpload;
