import moment from 'moment';
import { AnyAction, AsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SelfeAppStateModel } from 'models/app.model';
import {
  INIT_CAN_RETAKE_PHOTO,
  INIT_DEPOSIT_AMOUNT,
  INIT_ELEMENT_ID,
  INIT_FILTER_ID,
  INIT_FRAME_ID,
  INIT_LAYOUT_ID,
  INIT_PICKED_PHOTOS,
  INIT_PROCESSED_IMG_FILE_NAME,
  INIT_PROCESSED_VIDEO_FILE_NAME,
  INIT_QUANTITY_OF_PER_SHEET,
  INIT_QUANTITY_SHEET,
  INIT_SHOOTING_MODE_ID,
  INIT_THEME_DETAIL_ID,
  INIT_THEME_ID,
  INIT_TRANSACTION_ID,
  INIT_APPLIED_VOUCHER_CODE,
  SELFE_LIFE_CYCLE_STEP_DEFAULT,
  INIT_APPLIED_VOUCHER_VALUE,
  INIT_THEME_CATEGORY_ID,
  INIT_STICKER_CATEGORY_ID,
  INIT_LIST_STICKER,
  INIT_TIMES_MAPPING_CAPTURE_POSITION,
  INIT_BACKGROUND_ID,
  INIT_BACKGROUND_CATEGORY_ID,
  TIME_QR_CODE_PAYMENT,
} from 'constants/photo.const';
import { openCameraApiAction } from 'store/api/camera.slice-api';
import {
  createMomoTransactionApiAction,
  getBillAcceptorApiAction,
  openBillAcceptorApiAction,
} from 'store/api/payment.slice-api';
import { INIT_POPUP_STATE } from 'constants/modal.const';
import { mToSec } from 'helpers/math.helper';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const initialState: SelfeAppStateModel = {
  transactionId: INIT_TRANSACTION_ID,
  frameId: INIT_FRAME_ID,

  layoutId: INIT_LAYOUT_ID,
  quantityPicOfPerSheet: INIT_QUANTITY_OF_PER_SHEET,
  quantitySheet: INIT_QUANTITY_SHEET,

  isChoosePaymentMethod: false,
  isChooseQRCodeToPay: false,
  isShouldOpenBillAcceptor: false,
  isMomoTransactionLoading: false,
  momoPaymentAmount: 0,
  momoTransactionData: null,
  momoTransactionExpireDate: null,

  depositAmount: INIT_DEPOSIT_AMOUNT,
  appliedVoucherCode: INIT_APPLIED_VOUCHER_CODE,
  appliedVoucherValue: INIT_APPLIED_VOUCHER_VALUE,

  themeCategoryId: INIT_THEME_CATEGORY_ID,
  themeId: INIT_THEME_ID,
  themeDetailId: INIT_THEME_DETAIL_ID,
  elementId: INIT_ELEMENT_ID,
  filterId: INIT_FILTER_ID,

  listTimesMappingCapturePosition: INIT_TIMES_MAPPING_CAPTURE_POSITION,

  stickerCategoryId: INIT_STICKER_CATEGORY_ID,
  listSelectedSticker: INIT_LIST_STICKER,

  backgroundCategoryId: INIT_BACKGROUND_CATEGORY_ID,
  backgroundId: INIT_BACKGROUND_ID,

  shootingModeId: INIT_SHOOTING_MODE_ID,

  processedImgFileName: INIT_PROCESSED_IMG_FILE_NAME,
  processedVideoFileName: INIT_PROCESSED_VIDEO_FILE_NAME,

  pickedPhotos: INIT_PICKED_PHOTOS,
  isRetakePhoto: INIT_CAN_RETAKE_PHOTO,
  isShootingOfRetakingPhoto: false,
  wasRetakePhoto: false,

  modalConfirmState: { ...INIT_POPUP_STATE },
  LifeCycleStep: SELFE_LIFE_CYCLE_STEP_DEFAULT,

  appLoading: false,
  photoContainer: null,

  imgBgUrl: '',
  currentBackgroundId: 0,
};

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected');
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.endsWith('/fulfilled');
}

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending');
}

export const selfeAppSlice = createSlice({
  name: 'selfe-app',
  initialState,
  reducers: {
    setSelfeAppStateAction: (
      state,
      action: PayloadAction<Partial<SelfeAppStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as SelfeAppStateModel),
    resetSelfeAppStateAction: () => ({ ...initialState }),
    setBackgroundImg: (state, action: PayloadAction<string>) =>
      ({
        ...state,
        imgBgUrl: action.payload,
      } as SelfeAppStateModel),
    setCurrentBackgroundId: (state, action: PayloadAction<number>) =>
      ({
        ...state,
        currentBackgroundId: action.payload,
      } as SelfeAppStateModel),
  },
  extraReducers: (builder) => {
    // isAnyOf https://github.com/reduxjs/redux-toolkit/issues/429#issuecomment-810031743
    builder
      .addMatcher(getBillAcceptorApiAction.matchPending, (state) => ({
        ...state,
        isShouldOpenBillAcceptor: false,
      }))
      .addMatcher(getBillAcceptorApiAction.matchRejected, (state) => ({
        ...state,
        isShouldOpenBillAcceptor: false,
      }))
      .addMatcher(
        getBillAcceptorApiAction.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          isShouldOpenBillAcceptor: !payload,
        }),
      )
      .addMatcher(openBillAcceptorApiAction.matchPending, (state) => ({
        ...state,
        isShouldOpenBillAcceptor: false,
      }))
      .addMatcher(createMomoTransactionApiAction.matchPending, (state) => ({
        ...state,
        momoPaymentAmount: 0,
        isMomoTransactionLoading: true,
      }))
      .addMatcher(
        createMomoTransactionApiAction.matchFulfilled,
        (state, { payload }) => ({
          ...state,
          momoTransactionData:
            payload?.data?.resultCode === 0 ? payload?.data : null,
          momoTransactionExpireDate:
            payload?.data?.resultCode === 0
              ? moment()
                  .add(mToSec(TIME_QR_CODE_PAYMENT), 'seconds')
                  .clone()
                  .toDate()
              : null,
          momoPaymentAmount: payload?.data?.amount || 0,
          isMomoTransactionLoading: false,
        }),
      )
      .addMatcher(createMomoTransactionApiAction.matchRejected, (state) => ({
        ...state,
        momoTransactionData: null,
        momoTransactionExpireDate: null,
        momoPaymentAmount: 0,
        isMomoTransactionLoading: false,
      }))
      .addMatcher(openCameraApiAction.matchPending, (state) => ({
        ...state,
        // appLoading: true,
      }))
      .addMatcher(openCameraApiAction.matchRejected, (state) => ({
        ...state,
        // appLoading: false,
      }))
      .addMatcher(openCameraApiAction.matchFulfilled, (state) => ({
        ...state,
        // appLoading: false,
      }))
      .addMatcher(isPendingAction, (state) => ({
        ...state,
        // appLoading: true,
      }))
      .addMatcher(isFulfilledAction, (state) => ({
        ...state,
        // appLoading: false,
      }))
      .addMatcher(isRejectedAction, (state) => ({
        ...state,
        // appLoading: false,
      }));
  },
});

export const {
  setSelfeAppStateAction,
  resetSelfeAppStateAction,
  setBackgroundImg,
  setCurrentBackgroundId,
} = selfeAppSlice.actions;

export default selfeAppSlice.reducer;
