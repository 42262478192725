import { PrintingPhotoRouteConst, SelfeRouteConst } from './route.const';

export enum TRANSACTION_STATUS {
  CREATED = 1,
}

export enum MODULE_ID {
  PRINTING_BOOTH = 1,
  SELFE_BOOTH = 2,
}

export enum PRINTING_ID {
  PRINTING_PHOTO = 3,
  PRINTING_PORTRAIT = 4,
}

export const CURRENCY_SYMBOL = 'đ';
export const CAROUSEL_TRANSITION_TIME = 200;
export const PLAY_AUDIO_TICK_SECOND_IN_LAST = 10000;
export const KEYCODE_TO_TAKE_PHOTO = [37, 39];

export const TIME_INITIAL_APP_DURATION = 3000;

// Select frame
export const FRAME_TIME_DURATION = 30000;
export const FRAME_MORE_TIME_DURATION = 20000;
export const FRAME_TO_PAYMENT_DIRECTLY = 1; // mã id của frame 2x6 api trả về
export const DEFAULT_SHEET_OF_FRAME = 2; // số tấm ảnh mặc định cho 4x6

// select Layout
export const LAYOUT_TIME_DURATION = 30000;
export const LAYOUT_MORE_TIME_DURATION = 20000;
export const QUANTITY_TYPES = [1, 3, 4, 6, 8];
export const QUANTITY_DEFAULT = 4;

// select payment
export const PAYMENT_TIME_DURATION = 120000;
export const PAYMENT_MORE_TIME_DURATION = 20000;
export const PAYMENT_DEPOSIT_DEBOUNCE_TIME = 300;
export const CLOSE_AFTER_TIME_DURATION = 3000;
export const TIME_QR_CODE_PAYMENT = 600000;
export const TIME_BANKING_DEBOUNCE_TIME = 500;
export const TIME_REFRESHABLE_DURATION = 60000;

// Shooting
export const BUTTON_AUTO_CLICK_TIME_DURATION = 10000;
export const CONTINUE_TO_START_TIME_DURATION = 20000;
export const SHOOTING_MODE_TIME_DURATION = 30000;
export const SHOOTING_MODE_MORE_TIME_DURATION = 20000;
export const SHOOTING_TIME_DURATION = 180000;
export const SHOOTING_MORE_TIME_DURATION = 20000;
export const SHOOTING_TYPES = {
  COUNTDOWN: 1,
  BY_REMOTE: 2,
};
export const LIMIT_SHOWING_PER_TRANSACTION = 4;
export const SECOND_PER_COUNTDOWN = 10;
export const SECOND_VAL = 1000;
export const TIME_FREEZE_TAKE_PHOTO = 500;
export const TIME_TO_SHOW_PHOTO = 500;
export const TIME_TO_CAMERA_FLASH = 100;
export const TIME_DEBOUNCE_TAKE_PHOTO = 500;
export const TIME_SHOW_POPOVER_GUIDE_DURATION = 8000;
export const TIME_CONFIRM_BEFORE_NEXT = 20000;

// shooting position
export const NUMBER_OF_SHOOTING_POSITION_PER_PAGE = 4;
export const TIME_DEBOUNCE_UPDATE_ROBOT_ARM = 1000;

// Pick photo
export const PICKUP_PHOTO_TIME_DURATION = 60000;
export const PICKUP_PHOTO_MORE_TIME_DURATION = 20000;
export const PICK_PHOTO_GRID_SIZE = 3;
export const PICK_PHOTO_VERTICAL_SCREEN_ROW_SIZE = 2;
export const PICK_PHOTO_VERTICAL_SCREEN_COL_SIZE = 4;

// select Theme
export const EDIT_PHOTO_TIME_DURATION = 180000;
export const EDIT_PHOTO_MORE_TIME_DURATION = 20000;

export const THEME_TIME_DURATION = 60000;
export const THEME_MORE_TIME_DURATION = 20000;
export const ITEM_CATEGORY_SELECTABLE_PER_PAGE = 5;
export const ITEM_SELECTABLE_PER_PAGE = 9;
export const VERTICAL_SCREEN_ITEM_SELECTABLE_PER_PAGE = 6;

// Filter
export const FILTER_TIME_DURATION = 60000;
export const FILTER_MORE_TIME_DURATION = 20000;
export const FILTER_GRID_SIZE = 2;
export const TIME_DELAY_PROCESS_VIDEO = 2500;
export const TIME_TO_DELAY_REDIRECT_PRINT = 2000;

// Print
export const PRINT_TIME_DURATION = 60000;
export const PRINT_MORE_TIME_DURATION = 20000;

// id of page
export const PHOTO_PAGE = {
  HOME: '/',
  START_PAGE: '/',
  FRAME: '/frame',
  LAYOUT: '/layout',
  PAYMENT_METHOD: '/payment-method',
  PAYMENT: '/payment',
  QR_CODE: '/qr-code',
  SHOOTING_POSITION: '/shooting-position',
  SHOOTING_MODE: '/shooting-mode',
  SHOOTING: '/shooting',
  PICK_PHOTO: '/pick-photo',

  // edit photo
  EDIT_PHOTO: '/edit-photo',
  THEME: '/edit-photo/theme',
  ADD_FILTER: '/edit-photo/add-filter',
  STICKER: '/edit-photo/sticker',
  BACKGROUND: '/edit-photo/background',

  PRINT: '/print',
  GUIDE: '/guide',
  GUIDE_API: '/guide-api',
  PHOTO_GUIDE: '/photo-guide',
  PHOTO_GUIDE_2: '/photo-guide-2',
};

// id of page
export const PRINTING_PHOTO_PAGE = {
  HOME: '/',
  START_PAGE: '/',
  PAYMENT: '/pp/payment',
  QR_CODE: '/pp/qr-code',
  PRINT: '/pp/print',
};

export const PHOTO_WITH_BG_PREFIX = 'removebg_';

// init state value
export const SELFE_LIFE_CYCLE_STEP_DEFAULT = {
  [PHOTO_PAGE.START_PAGE]: {
    id: PHOTO_PAGE.START_PAGE,
    route: SelfeRouteConst.START_PAGE,
    prevId: PHOTO_PAGE.START_PAGE,
    nextId: PHOTO_PAGE.FRAME,
    isStartNode: true,
    hidePrevNext: true,
  },
  [PHOTO_PAGE.FRAME]: {
    id: PHOTO_PAGE.FRAME,
    route: SelfeRouteConst.FRAME,
    prevId: PHOTO_PAGE.START_PAGE,
    nextId: PHOTO_PAGE.LAYOUT,
    timeUpDuration: FRAME_TIME_DURATION,
    moreTimeUpDuration: FRAME_MORE_TIME_DURATION,
    hidePrevNext: true,
  },
  [PHOTO_PAGE.LAYOUT]: {
    id: PHOTO_PAGE.LAYOUT,
    route: SelfeRouteConst.LAYOUT,
    prevId: PHOTO_PAGE.FRAME,
    nextId: PHOTO_PAGE.PAYMENT_METHOD,
    timeUpDuration: LAYOUT_TIME_DURATION,
    moreTimeUpDuration: LAYOUT_MORE_TIME_DURATION,
    hideNext: true,
  },

  [PHOTO_PAGE.PAYMENT_METHOD]: {
    id: PHOTO_PAGE.PAYMENT_METHOD,
    route: SelfeRouteConst.PAYMENT_METHOD,
    prevId: PHOTO_PAGE.LAYOUT,
    nextId: PHOTO_PAGE.PAYMENT,
    isConditionRoute: true,
    timeUpDuration: PAYMENT_TIME_DURATION,
    moreTimeUpDuration: PAYMENT_MORE_TIME_DURATION,
    hideNext: true,
  },
  [PHOTO_PAGE.PAYMENT]: {
    id: PHOTO_PAGE.PAYMENT,
    route: SelfeRouteConst.PAYMENT,
    prevId: PHOTO_PAGE.LAYOUT,
    nextId: PHOTO_PAGE.QR_CODE,
    isConditionRoute: true,
    timeUpDuration: PAYMENT_TIME_DURATION,
    moreTimeUpDuration: PAYMENT_MORE_TIME_DURATION,
    hidePrevNext: true,
    isPageUsePayment: true,
  },
  [PHOTO_PAGE.QR_CODE]: {
    id: PHOTO_PAGE.QR_CODE,
    route: SelfeRouteConst.QR_CODE,
    prevId: PHOTO_PAGE.PAYMENT,
    nextId: PHOTO_PAGE.SHOOTING_POSITION,
    isConditionRoute: true,
    timeUpDuration: PAYMENT_TIME_DURATION,
    moreTimeUpDuration: PAYMENT_MORE_TIME_DURATION,
    hidePrevNext: true,
  },

  [PHOTO_PAGE.SHOOTING_POSITION]: {
    id: PHOTO_PAGE.SHOOTING_POSITION,
    route: SelfeRouteConst.SHOOTING_POSITION,
    prevId: PHOTO_PAGE.PAYMENT,
    nextId: PHOTO_PAGE.SHOOTING_MODE,
    isConditionRoute: true,
    timeUpDuration: SHOOTING_MODE_TIME_DURATION,
    moreTimeUpDuration: SHOOTING_MODE_MORE_TIME_DURATION,
    hidePrevNext: true,
    isOpenCameraLiveView: true,
  },
  [PHOTO_PAGE.SHOOTING_MODE]: {
    id: PHOTO_PAGE.SHOOTING_MODE,
    route: SelfeRouteConst.SHOOTING_MODE,
    prevId: PHOTO_PAGE.PAYMENT,
    nextId: PHOTO_PAGE.SHOOTING,
    hidePrevNext: true,
    timeUpDuration: SHOOTING_MODE_TIME_DURATION,
    moreTimeUpDuration: SHOOTING_MODE_MORE_TIME_DURATION,
    forwardAfterTimeUp: true,
    isOpenCameraLiveView: true,
  },
  [PHOTO_PAGE.SHOOTING]: {
    id: PHOTO_PAGE.SHOOTING,
    route: SelfeRouteConst.SHOOTING,
    prevId: PHOTO_PAGE.SHOOTING_MODE,
    nextId: PHOTO_PAGE.PICK_PHOTO,
    timeUpDuration: SHOOTING_TIME_DURATION,
    moreTimeUpDuration: SHOOTING_MORE_TIME_DURATION,
    hidePrevNext: true,
    isPageUseCamera: true,
    forwardAfterTimeUp: true,
  },
  [PHOTO_PAGE.PICK_PHOTO]: {
    id: PHOTO_PAGE.PICK_PHOTO,
    route: SelfeRouteConst.PICK_PHOTO,
    prevId: PHOTO_PAGE.SHOOTING,
    nextId: PHOTO_PAGE.THEME,
    timeUpDuration: PICKUP_PHOTO_TIME_DURATION,
    moreTimeUpDuration: PICKUP_PHOTO_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
    isStopCameraLiveView: true,
  },
  [PHOTO_PAGE.THEME]: {
    id: PHOTO_PAGE.THEME,
    route: SelfeRouteConst.THEME,
    prevId: PHOTO_PAGE.PICK_PHOTO,
    nextId: PHOTO_PAGE.PRINT,
    timeUpDuration: EDIT_PHOTO_TIME_DURATION,
    moreTimeUpDuration: EDIT_PHOTO_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
  },
  [PHOTO_PAGE.BACKGROUND]: {
    id: PHOTO_PAGE.BACKGROUND,
    route: SelfeRouteConst.BACKGROUND,
    prevId: PHOTO_PAGE.PICK_PHOTO,
    nextId: PHOTO_PAGE.PRINT,
    timeUpDuration: EDIT_PHOTO_TIME_DURATION,
    moreTimeUpDuration: EDIT_PHOTO_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
  },
  [PHOTO_PAGE.STICKER]: {
    id: PHOTO_PAGE.STICKER,
    route: SelfeRouteConst.STICKER,
    prevId: PHOTO_PAGE.PICK_PHOTO,
    nextId: PHOTO_PAGE.PRINT,
    timeUpDuration: EDIT_PHOTO_TIME_DURATION,
    moreTimeUpDuration: EDIT_PHOTO_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
  },
  [PHOTO_PAGE.ADD_FILTER]: {
    id: PHOTO_PAGE.ADD_FILTER,
    route: SelfeRouteConst.ADD_FILTER,
    prevId: PHOTO_PAGE.PICK_PHOTO,
    nextId: PHOTO_PAGE.PRINT,
    timeUpDuration: EDIT_PHOTO_TIME_DURATION,
    moreTimeUpDuration: EDIT_PHOTO_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
  },
  [PHOTO_PAGE.PRINT]: {
    id: PHOTO_PAGE.PRINT,
    route: SelfeRouteConst.PRINT,
    prevId: PHOTO_PAGE.ADD_FILTER,
    nextId: PHOTO_PAGE.START_PAGE,
    timeUpDuration: PRINT_TIME_DURATION,
    moreTimeUpDuration: PRINT_MORE_TIME_DURATION,
    hidePrevNext: true,
    forwardAfterTimeUp: true,
  },
};

// init state value
export const PRINTING_PHOTO_LIFE_CYCLE_STEP_DEFAULT = {
  [PrintingPhotoRouteConst.START_PAGE]: {
    id: PrintingPhotoRouteConst.START_PAGE,
    route: PrintingPhotoRouteConst.START_PAGE,
    prevId: PrintingPhotoRouteConst.START_PAGE,
    nextId: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    isStartNode: true,
    hidePrev: true,
  },
  [PrintingPhotoRouteConst.PHOTO_UPLOAD]: {
    id: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    route: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    prevId: PrintingPhotoRouteConst.START_PAGE,
    nextId: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    hidePrevNext: true,
  },
  [PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO]: {
    id: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    route: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    prevId: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    nextId: PrintingPhotoRouteConst.EDIT_PHOTO,
    hidePrevNext: true,
  },
  [PrintingPhotoRouteConst.EDIT_PHOTO]: {
    id: PrintingPhotoRouteConst.EDIT_PHOTO,
    route: PrintingPhotoRouteConst.EDIT_PHOTO,
    prevId: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    nextId: PrintingPhotoRouteConst.PAYMENT,
    hidePrevNext: true,
  },
  [PrintingPhotoRouteConst.PAYMENT]: {
    id: PrintingPhotoRouteConst.PAYMENT,
    route: PrintingPhotoRouteConst.PAYMENT,
    prevId: PrintingPhotoRouteConst.EDIT_PHOTO,
    nextId: PrintingPhotoRouteConst.PAYMENT,
    hidePrevNext: true,
  },
  [PrintingPhotoRouteConst.WAIT_FOR_PRINTING]: {
    id: PrintingPhotoRouteConst.WAIT_FOR_PRINTING,
    route: PrintingPhotoRouteConst.WAIT_FOR_PRINTING,
    prevId: PrintingPhotoRouteConst.PAYMENT,
    nextId: PrintingPhotoRouteConst.WAIT_FOR_PRINTING,
    hidePrevNext: true,
  },
};

// init state value
export const INIT_FRAME_ID = 0;
export const INIT_LAYOUT_ID = 0;
export const INIT_QUANTITY_OF_PER_SHEET = 0;
export const INIT_QUANTITY_SHEET = 1;
export const INIT_DEPOSIT_AMOUNT = 0;
export const INIT_APPLIED_VOUCHER_CODE = '';
export const INIT_APPLIED_VOUCHER_VALUE = 0;
export const INIT_TIMES_MAPPING_CAPTURE_POSITION = [];
export const INIT_THEME_ID = 0;
export const INIT_THEME_CATEGORY_ID = 0;
export const INIT_STICKER_CATEGORY_ID = 0;
export const INIT_BACKGROUND_CATEGORY_ID = 0;
export const INIT_BACKGROUND_ID = 0;
export const INIT_THEME_DETAIL_ID = 0;
export const INIT_ELEMENT_ID = 0;
export const INIT_FILTER_ID = 1;
export const INIT_SHOOTING_MODE_ID = 0;
export const INIT_TRANSACTION_ID = '';
export const INIT_PROCESSED_IMG_FILE_NAME = '';
export const INIT_PROCESSED_VIDEO_FILE_NAME = '';
export const INIT_PICKED_PHOTOS = [];
export const INIT_CAN_RETAKE_PHOTO = false;
export const INIT_LIST_STICKER = [];
