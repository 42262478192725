import React, { useEffect, useRef } from 'react';
import { pick } from 'lodash';
import { PrintingPhotoRouteConst } from 'constants/route.const';
import CustomRoutes from 'components/custom-routes/CustomRoutes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from 'constants/i18n.const';
import { useAppSelector } from 'store/store-hooks';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import Background from 'components/background/Background';
import PageActions from 'components/page-actions/PageActions';
import Loader from 'components/loader/Loader';
import { PrintingAppContext } from 'context/PrintingAppContext';
import { useSoundContext } from 'context/SoundContext';
import { APP_CONTENT_ID } from 'constants/dom-element.const';
import PrintingStartPage from 'containers/printing-photo-booth/start-page/PrintingStartPage';
import PrintingPhotoUpload from 'containers/printing-photo-booth/printing-photo-upload/PrintingPhotoUpload';
import PrintingSelectPhoto from 'containers/printing-photo-booth/printing-select-photo/PrintingSelectPhoto';
import PrintingEditPhoto from 'containers/printing-photo-booth/printing-edit-photo/PrintingEditPhoto';
import PrintingPayment from 'containers/printing-photo-booth/printing-payment/PrintingPayment';
import PrintingWaitForPrinting from 'containers/printing-photo-booth/printing-wait-for-printing/PrintingWaitForPrinting';
import PrintingPortraitLayout from 'containers/printing-photo-booth/printing-portrait-layout/PrintingPortraitLayout';
import './printing-photo-layout.css';

const router = [
  {
    path: PrintingPhotoRouteConst.HOME,
    element: <PrintingStartPage />,
  },
  {
    path: PrintingPhotoRouteConst.PHOTO_UPLOAD,
    element: <PrintingPhotoUpload />,
  },
  {
    path: PrintingPhotoRouteConst.SELECT_UPLOADED_PHOTO,
    element: <PrintingSelectPhoto />,
  },
  {
    path: PrintingPhotoRouteConst.EDIT_PHOTO,
    element: <PrintingEditPhoto />,
  },
  {
    path: PrintingPhotoRouteConst.PAYMENT,
    element: <PrintingPayment />,
  },
  {
    path: PrintingPhotoRouteConst.WAIT_FOR_PRINTING,
    element: <PrintingWaitForPrinting />,
  },
  {
    path: PrintingPhotoRouteConst.SELECT_PORTRAIT_LAYOUT,
    element: <PrintingPortraitLayout />,
  },
];

function PrintingPhotoLayout() {
  useTranslation([
    I18nNamespace.COMMON,
    I18nNamespace.PAGE,
    I18nNamespace.WARNING,
  ]);
  const navigate = useNavigate();
  const { audio } = useSoundContext();
  const { getNextPath, getPrevPath, currentRoute } = useNewPhotoLifeCycleStep();
  const { appLoading } = useAppSelector((state) => state.printingApp);

  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };
  const handleContinue = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      (videoRef.current as any).playbackRate = 1.4;
    }
  }, []);

  return (
    <PrintingAppContext>
      <Loader
        id={APP_CONTENT_ID}
        className="font-Nunito no-drag select-none overflow-hidden w-screen h-screen flex justify-center app-content"
        loading={appLoading}
        outerSpinProps={{ className: 'app-content-pb-loader-icon-wrapper' }}
      >
        <Background />
        <CustomRoutes
          routes={router}
          outerRoute={PrintingPhotoRouteConst.HOME}
        />
        <PageActions
          {...pick(currentRoute, [
            'hidePrevNext',
            'hidePrev',
            'hideNext',
            'PrevButtonProps',
            'NextButtonProps',
          ])}
          handleBack={handleBack}
          handleContinue={handleContinue}
        />
      </Loader>
    </PrintingAppContext>
  );
}

export default PrintingPhotoLayout;
