import React, { FC, PropsWithChildren, useContext } from 'react';
import { useGetBillAcceptorApiActionQuery } from 'store/api/payment.slice-api';
import { useGetBoothApiActionQuery } from 'store/api/booth.slice-api';

export const Context = React.createContext<PrintingAppContextReturnTypes>({});

interface PrintingAppContextProps {}
type PrintingAppContextReturnTypes = {};

export const PrintingAppContext: FC<
  PropsWithChildren<PrintingAppContextProps>
> = ({ children }) => {
  useGetBoothApiActionQuery();
  useGetBillAcceptorApiActionQuery();

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

export const usePrintingAppContext = () => useContext(Context);
