export const SelfeRouteConst = {
  HOME: '/',
  START_PAGE: '/',
  FRAME: '/frame',
  LAYOUT: '/layout',
  PAYMENT_METHOD: '/payment-method',
  PAYMENT: '/payment',
  QR_CODE: '/qr-code',
  SHOOTING_POSITION: '/shooting-position',
  SHOOTING_MODE: '/shooting-mode',
  SHOOTING: '/shooting',
  PICK_PHOTO: '/pick-photo',

  // edit photo
  EDIT_PHOTO: '/edit-photo',
  THEME: '/edit-photo/theme',
  ADD_FILTER: '/edit-photo/add-filter',
  STICKER: '/edit-photo/sticker',
  BACKGROUND: '/edit-photo/background',

  PRINT: '/print',
  GUIDE: '/guide',
  GUIDE_API: '/guide-api',
  PHOTO_GUIDE: '/photo-guide',
  PHOTO_GUIDE_2: '/photo-guide-2',
};

export const PrintingPhotoRouteConst = {
  HOME: '/pp',
  START_PAGE: '/pp',
  PHOTO_UPLOAD: '/pp/photo-upload',
  SELECT_UPLOADED_PHOTO: '/pp/select-uploaded-photo',
  EDIT_PHOTO: '/pp/edit-photo',
  PAYMENT: '/pp/payment',
  WAIT_FOR_PRINTING: '/pp/wait-for-print',
  SELECT_PORTRAIT_LAYOUT: '/pp/select-portrait-layout',
};

export const QUERY_STRING = {
  CAM_DEVICE: 'use_cam',
  FE_CLOUD: {
    TRANSACTION_ID: 't',
    BOOTH_CODE: 'bc',
  },
};
