import React, { useState } from 'react';
import cx from 'classnames';
import PageActions from 'components/page-actions/PageActions';
import TimerText from 'components/timer-text/TimerText';
import TitleAndLogo from 'components/title-and-logo/TitleAndLogo';
import Typography from 'components/typography/Typography';
import Select from 'components/select/Select';
import { TYPOGRAPHY_VARIANTS } from 'components/typography/typography-utils';
import Button from 'components/button/Button';
import InputNumber from 'components/input/InputNumber';
import { I18nNamespace } from 'constants/i18n.const';
import { useCheckPayment } from 'containers/selfe-booth/payment/useCheckPayment';
import { useAppContext } from 'context/AppContext';
import { useSoundContext } from 'context/SoundContext';
import { useNewPhotoLifeCycleStep } from 'hooks/useNewPhotoLifeCycleStep';
import { usePageTimer } from 'hooks/usePageTimer';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { filter, size } from 'lodash';
import { AssetIcon } from 'assets/icon/Icons';
import { setPrintingAppStateAction } from 'store/features/app/printingAppSlice';
import { includesId } from 'helpers/array.helper';
import { isEqualVal } from 'helpers/string.helper';
import Cropper from 'components/cropper/Cropper';
import './printing-edit-photo.css';

function PrintingEditPhoto() {
  const { T } = useTranslation([I18nNamespace.COMMON, I18nNamespace.PAGE]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getPrevPath, getNextPath } = useNewPhotoLifeCycleStep();
  const { audio } = useSoundContext();
  const { isOnline } = useAppContext();
  const { second } = usePageTimer();
  const { isEnoughDeposit } = useCheckPayment();

  const [isShowEditPanel, setIsShowEditPanel] = useState(false);

  const { numberOfPrint, selectedUploadedPhoto } = useAppSelector(
    (state) => state.printingApp,
  );

  const { isVerticalScreen } = useAppSelector((s) => s.booth);

  // click Back
  const handleBack = () => {
    audio?.playBackClick?.();
    navigate(getPrevPath() || '');
  };

  // click Back
  const handleNext = () => {
    audio?.playContinueClick?.();
    navigate(getNextPath() || '');
  };

  const handleToggleShowEditPanel = () => setIsShowEditPanel((s) => !s);

  const handleSelectPhoto = (fileName: string) => () =>
    dispatch(
      setPrintingAppStateAction({
        // eslint-disable-next-line no-nested-ternary
        selectedUploadedPhoto: includesId(selectedUploadedPhoto, fileName)
          ? filter(selectedUploadedPhoto, (o) => !isEqualVal(o, fileName))
          : size(selectedUploadedPhoto) >= numberOfPrint
          ? selectedUploadedPhoto
          : [...selectedUploadedPhoto, fileName],
      }),
    );

  const renderPaymentActions = () => (
    <PageActions
      NextButtonProps={{
        children: T('common:continue'),
        disabled: !isEnoughDeposit || !isOnline,
      }}
      handleBack={handleBack}
      handleContinue={handleNext}
      className={cx({
        'printing-edit-photo__page-actions--vertical': isVerticalScreen,
      })}
      // hideNext
    />
  );

  return (
    <>
      <div className="printing-edit-photo">
        <TimerText second={second} />
        {isVerticalScreen && renderPaymentActions()}
        {!isVerticalScreen && (
          <div className="page-title-margin printing-edit-photo__page-title">
            <Typography
              variant={TYPOGRAPHY_VARIANTS.H1}
              data-text={T(`${I18nNamespace.COMMON}:editPhoto`)}
              className="page-title"
            >
              {T(`${I18nNamespace.COMMON}:editPhoto`)}
            </Typography>
          </div>
        )}
        <div className="printing-edit-photo__wrapper">
          <div className="printing-edit-photo__preview-edit-wrapper">
            <div className={cx('printing-edit-photo__preview-image')}>
              <div
                className="dashed-border-select-frame printing-edit-photo__preview-image-wrapper"
                // style={{
                //   aspectRatio: `${currentLayout?.ratioX || 1} / ${
                //     currentLayout?.ratioY || 1
                //   }`,
                // }}
              >
                <div className="printing-edit-photo__cropper-wrapper">
                  <Cropper
                    className="printing-edit-photo__cropper"
                    imgSrc="https://fengyuanchen.github.io/cropperjs/images/picture.jpg"
                  />
                </div>
              </div>
            </div>
            {isShowEditPanel && (
              <div className="printing-edit-photo__edit-panel">
                <Typography className="printing-edit-photo__edit-panel-title">
                  {T(`${I18nNamespace.COMMON}:cropPhoto`)}
                </Typography>
                <div className="printing-edit-photo__edit-panel--row">
                  <Select
                    value={1}
                    options={[{ value: 1, label: 'Tỷ lệ ảnh      2:3' }]}
                    prefixIcon={<AssetIcon.PictureOutline />}
                  />
                </div>
                <div className="printing-edit-photo__edit-panel--row">
                  <div className="printing-edit-photo__edit-panel--col">
                    <Select
                      value={1}
                      options={[
                        { value: 1, label: 'Xoay 1' },
                        { value: 2, label: 'Xoay 2' },
                        { value: 3, label: 'Xoay 3' },
                      ]}
                      prefixIcon={<AssetIcon.PictureOutline />}
                    />
                    <Select
                      value={1}
                      options={[
                        { value: 1, label: 'Lật 1' },
                        { value: 2, label: 'Lật 2' },
                      ]}
                      prefixIcon={<AssetIcon.PictureOutline />}
                    />
                  </div>
                </div>
                <div className="printing-edit-photo__edit-panel--row">
                  <Button className="printing-edit-photo__btn-re-setup">
                    <AssetIcon.PictureOutline />
                    <Typography variant={TYPOGRAPHY_VARIANTS.XS}>
                      {T(`${I18nNamespace.COMMON}:reinstall`)}
                    </Typography>
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="printing-edit-photo__edit-actions">
            <div className="printing-edit-photo__edit-action-select-amount">
              <Typography>
                {T(`${I18nNamespace.COMMON}:numberOfPrint`)}:
              </Typography>
              <InputNumber />
            </div>
            <div className="printing-edit-photo__edit-action-crop">
              <button
                type="button"
                className="printing-edit-photo__btn-show-crop"
                onClick={handleToggleShowEditPanel}
              >
                <span className="printing-edit-photo__icon-crop">
                  <AssetIcon.PictureOutline />
                </span>
                <Typography>
                  {T(`${I18nNamespace.COMMON}:cropAndFlip`)}
                </Typography>
              </button>
            </div>
          </div>
        </div>
      </div>
      {!isVerticalScreen && renderPaymentActions()}
      {isVerticalScreen && (
        <TitleAndLogo text={T(`${I18nNamespace.COMMON}:uploadedPhoto`)} />
      )}
    </>
  );
}

export default PrintingEditPhoto;
